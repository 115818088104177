<template>
  <VField v-slot="{field, meta, errors}" :name="name" type="checkbox">
    <label class="flex items-start cursor-pointer relative">
      <input v-model="internalChecked" class="mr-2" type="checkbox" @change="onChange" />
      <span class="font-roboto-normal text-sm text-[#333333] w-full leading-6 dark:text-white block mt-px">
        <slot />
      </span>
    </label>
    <VErrorMessage :name="name" as="div" class="text-cs-red text-sm" />
  </VField>
</template>

<script lang="ts" setup>
interface ComponentProps {
  name: string
  checked?: boolean
  changeHandler?: Function
}

const props = withDefaults(defineProps<ComponentProps>(), {
  checked: false
})

const internalChecked = ref<boolean>(props.checked || false)

const onChange = (e) => {
  if (props.changeHandler) {
    props.changeHandler(internalChecked.value, props.name)
  }
}
</script>

<style>
/* Basic styling */

[type='checkbox'] {
  width: 17px;
  height: 17px;
  margin-top: 5px;
  -webkit-appearance: none;
  background: none;
  transition: background 300ms;
  cursor: pointer;
}

/* Pseudo element for check styling */

[type='checkbox']::before {
  content: '';
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: 3px;
  border: 1px solid #312d3c;
  background-color: #ffffff;
  background-size: contain;
}

html.dark [type='checkbox']::before {
  border: 1px solid #868686;
}

html.dark [type='checkbox']::before {
  background-color: transparent;
}

/* Checked */
[type='checkbox']:checked::before {
  background-color: #39c0c4;
  border: none;
}

html.dark [type='checkbox']:checked::before {
  background-color: #39c0c4;
}

[type='checkbox']:checked::after {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 7px;
  width: 4px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>
